<route>
{
  "meta": {
    "permission": [
      "expenses.view_expense"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <i-table
      :title="$tc('expenses_recurring', 2)"
      :headers="headers"
      app="expenses.expense"
      api="expense"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
      :opt="{ recurrent: true }"
    >
      <template v-slot:item.date="{ item }">
        <span v-text="dateFormat(item.date)"></span>
      </template>
      <template v-slot:item.next_date="{ item }">
        <span v-text="dateFormat(item.next_date)"></span>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    headers() {
      return [
        {
          text: this.expenseBtn.category ? this.$tc('category', 1) : '',
          value: this.expenseBtn.category ? 'category.name' : ''
        },
        {
          text: this.expenseBtn.account ? this.$t('account') : '',
          value: this.expenseBtn.account ? 'account.name' : ''
        },
        {
          text: this.$t('date'),
          value: 'date'
        },
        {
          text: this.$t('description'),
          value: 'description'
        },
        {
          text: this.$t('operator'),
          value: 'operator'
        },
        {
          text: this.$tc('payment', 1),
          value: 'payment'
        },
        {
          text: this.$t('reference'),
          value: 'reference'
        },
        {
          text: this.$t('next_date'),
          value: 'next_date'
        },
        {
          text:
            this.expenseBtn.delete || this.expenseBtn.edit
              ? this.$t('action')
              : '',
          value: this.expenseBtn.delete || this.expenseBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    expenseBtn() {
      return {
        delete:
          this.getPermissions([`expenses.delete_expense`]) || this.isAdmin,
        edit: this.getPermissions([`expenses.change_expense`]) || this.isAdmin,
        account: this.getPermissions([`expenses.view_account`]) || this.isAdmin,
        category:
          this.getPermissions([`expenses.view_category`]) || this.isAdmin
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'expensesRecurring-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
